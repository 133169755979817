<template>
    <div class="cart">

        <div class="pageHeader basketHeader">
            <div class="contentHeader basketHeaderContent">
                <a href="javascript:void(0)" @click="backToScanner" class="backBtn">
                    <img src="@/assets/img/back.png">
                </a>
                <span>{{$t('basket')}} - {{nbrProducts}} {{productWording}}</span>
            </div>

        </div>

        <div v-if="nbrProducts < 1" class="noProducts" >{{$t('emptyBasket')}}</div>
        <div v-else class="cartProducts" style="padding-bottom: 120px;padding-top: 50px;">

            <div v-for="cartProduct in fullBasket.products" :key="cartProduct.product.id" class="cardContainer">
                <div class="cartCard cardTemplateCartProduct row no-gutters">
                    <div v-if="cartProduct.qtyLocked" class="scannerTag">
                        <img src="@/assets/img/qrcode.png" alt="qrcode" class="qrcode_icon">
                    </div>
                    <div class="col-3 col-md-2 align-self-center" style="float:left;">
                        <img style="border:0" class="img-thumbnail imgProductCart" :src="img_url+cartProduct.product.image + '_t1'" />
                    </div>
                    <div class="product col-9 col-md-10 row no-gutters">
                        <div  v-if="getLang() == 'fr'">
                            <div class="nameProductCart col-12 align-self-center no-gutters">{{cartProduct.product.nameFr}}</div>
                            <div class="categoryProductCart col-12 align-self-center no-gutters">{{cartProduct.product.category.nameFr}} </div>
                        </div>
                        <div v-else>
                            <div class="nameProductCart col-12 align-self-center no-gutters">{{cartProduct.product.nameEn}}</div>
                            <div class="categoryProductCart col-12 align-self-center no-gutters">{{cartProduct.product.category.nameEn}} </div>
                        </div>
                        <div class="col-12 row align-self-end no-gutters">
                            <div class="col-4 col-md-2 no-gutters">
                                <p class="priceProduct">{{formatPrice(cartProduct.product.price.toFixed(2))}}</p>
                            </div>

                            <div class="col-2 no-gutters">
                                <img src="@/assets/img/picto_delete.png" @click="deleteFromBasket(cartProduct)" class="deleteProduct">
                            </div>
                            <div class="col-6 col-md-7 row no-gutters ml-auto">
                                <div class="linkToCart ml-auto">
                                    <img v-if="!cartProduct.qtyLocked" src="@/assets/img/picto_moinspanier.png" @click="decrQty(cartProduct)" class="lessProduct"/>
                                    <span class="qtyProduct">{{cartProduct.qty}}</span>
                                    <img  v-if="!cartProduct.qtyLocked" src="@/assets/img/picto_pluspanier.png" @click="incrQty(cartProduct)" class="moreProduct"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="row basketFooter footer">
            <div class="amountContainer">

                <div v-if="nbrProducts > 0" class="emptyBasket">
                    <a href="javascript:void(0)" id="emptyBasket" v-on:click="emptyBasket">{{$t('cleanBakset')}}</a>
                </div>

                <div class="amount">
                    Total : <strong v-if="nbrProducts>0">{{formatPrice(fullBasket.totalAmount.toFixed(2))}}</strong>
                    <strong v-else>{{formatPrice(0.00)}}</strong>
                </div>

            </div>
            <div class="footerActions">
                <div class="btn backBtn" @click="backToScanner">{{$t('backToScanner')}}</div>
                <button v-b-modal.emailModal style="margin-left: 25px;" :class="{'btn' : true, 'validateBtn' : true, 'btn-active': nbrProducts>0}" :disabled="nbrProducts<1">{{$t('checkout')}}</button>

                <b-modal id="emailModal" hide-footer centered :title="$t('modalEmailTitle')">
                    <div class="modal-body">
                        <form v-on:submit.prevent="toPaymentMethod">
                            <label for="email"><strong>{{$t('emailLabel')}}</strong></label>
                            <input type="email" class="form-control custom-input" id="email" v-model="customerEmail" name="" required autofocus>
                            <div class="checkbox-group">
                                <input type="checkbox" class="" id="gts" required>
                                <label for="gts" id="label-gts">{{$t('agreeTerms')}} <u>{{$t('terms')}}</u></label>
                            </div>
                            <button type="submit" class="btn btn-active">{{$t('confirm')}}</button>
                        </form>

                    </div>

                </b-modal>
            </div>

        </div>
    </div>
</template>

<script>
    var basket = require('@/assets/js/Basket');
    var Utils = require("@/assets/js/Utils");
    var Customer = require("@/assets/js/Customer");
    var Stats = require("@/assets/js/Stats");

    export default {
        name: 'Basket',
        data: function() {
            return{
                fullBasket : {
                    "id": 0,
                    "totalAmount": 0,
                    "products": [],
                    "lockedProducts" :[]
                },
                img_url:"",
                customerEmail : "",
                fromRoute : null
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.fromRoute = from;
            })
        },
        created(){
            
            this.fullBasket = this.$parent.basket;
            this.customerEmail = localStorage.getItem("DKC_customerEmail");
            Utils.setContext(this);
            this.img_url = Utils.getConfig().url_img;
        },
        mounted(){
            Stats.setStats({nbBasketAccess: 1});
        },
        methods:{
            backToScanner: function(){
                this.$parent.closeHandler();
                this.$router.push(localStorage.getItem("DKC_base") + "/scan");
            },
            incrQty: function(productToAdd) {
                basket.addProductToLocalBasket(productToAdd.product);
                this.updateBasket();
                Stats.setStats({nbBasketIncrease: 1});
            },
            deleteFromBasket: function(productToDelete) {
                basket.deleteProductFromLocalBasket(productToDelete);
                this.updateBasket();
                Stats.setStats({nbProductDeleted: 1});
            },
            decrQty: function(productToDec) {
                basket.decreaseProductFromLocalBasket(productToDec.product);
                this.updateBasket();
                Stats.setStats({nbBasketDecrease: 1});
            },
            emptyBasket: function() {
                this.nbProductDeleted += this.fullBasket.products.length;
                basket.emptyLocalBasket();
                this.updateBasket();
                Stats.setStats({nbBasketsEmpty: 1});
            },
            toPaymentMethod(){
                var self = this;
                if(this.customerEmail != localStorage.getItem("DKC_customerEmail")){
                    Customer.updateCustomerEmail(this.customerEmail, function(error){
                        if(!error){
                            localStorage.setItem("DKC_customerEmail", self.customerEmail);
                        }
                    });
                }
                this.$router.push(localStorage.getItem("DKC_base") + '/paymentMethods');
            },
            formatPrice(price) {
                return Utils.formatPrice(price);
            },
            updateBasket(){
                this.$parent.basketChange();
                this.fullBasket = this.$parent.basket;
            },
            statsBasketConsultation(){
                var parameters = {
                    nbBasketAccess: 1
                }
                Stats.setStats(parameters);
            },
            getLang(){
                return this.$root.$i18n.locale;
            }
        },
        computed:{
            nbrProducts : function() {
                var count = 0;
                if(this.fullBasket.products){
                    this.fullBasket.products.forEach(function(product) {
                        count+=product.qty;
                    });
                    return count
                }
            },
            productWording : function() {
                var word = this.$t('product');
                if(this.nbrProducts > 1 ){
                    word = word + 's';
                }
                return word
            },
        },
        beforeRouteLeave(to, from, next) {
            /* eslint-disable */
            //this.statsBasketModifications();
            //this.statsBasketConsultation();
            next();
        }
    }
</script>

<style scoped>

</style>